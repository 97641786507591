/*
 * @Author: ChenYaJin
 * @Date: 2023-07-07 10:32:18
 * @LastEditors: LiZhiWei
 * @LastEditTime: 2024-04-24 10:47:11
 * @Description: 企业相关表单配置
 */
import { BehaviorAction } from '@/models/behavior'
import type { IFormValidateCallback } from '@/models/common'
import { Employee } from '@/models/employee'
import { ETableItemType } from '@/models/enums'
import { Project } from '@/models/project'
import type { ITableSetting } from '@/models/table-setting'
import { testIdCard } from '@/utils'
export const projectTableSetting: ITableSetting<Project> = {
  setting: [
    [
      {
        label: '项目名称',
        fieldName: 'name',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入项目名称',
        placeholder: '请输入项目名称',
      },
      {
        label: '项目参与方式',
        fieldName: 'way',
        type: ETableItemType.SELECT,
        required: true,
        optionsName: 'way',
        condition: {
          outsource: {
            matchCode: 'outsource',
            fieldName: 'belowWay',
            optionsName: 'outsource',
            type: ETableItemType.SELECT,
            required: true,
            multiple: true,
          },
        },
        requiredMessage: '请输入项目性质',
        placeholder: '请输入项目性质',
      },
    ],
    [
      {
        label: '项目性质',
        fieldName: 'type',
        type: ETableItemType.SELECT,
        optionsName: 'type',
        condition: {
          residence: {
            matchCode: 'residence',
            fieldName: 'belowType',
            optionsName: 'residence',
            type: ETableItemType.SELECT,
            required: true,
            multiple: false,
          },
          other: {
            matchCode: 'other',
            fieldName: 'belowType',
            optionsName: 'other',
            type: ETableItemType.SELECT,
            required: true,
            multiple: false,
          },
        },
        required: true,
        requiredMessage: '请输入项目性质',
        placeholder: '请输入项目性质',
      },
      {
        label: '是否是保障性住房',
        fieldName: 'supportability',
        type: ETableItemType.RADIO,
        required: true,
        requiredMessage: '',
        placeholder: '',
      },
    ],
    [
      {
        label: '项目详细地址',
        fieldName: 'address',
        type: ETableItemType.ADDRESS,
        required: true,
        requiredMessage: '请输入项目详细地址',
        placeholder: '请输入项目详细地址',
      },
      {
        label: '项目面积（m²）',
        fieldName: 'extent',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入项目面积',
        placeholder: '取值自项目建筑面积或合同面积',
      },
    ],
    [
      {
        label: '合同生效日期',
        fieldName: 'contractStartAt',
        type: ETableItemType.DATE,
        placeholder: '请选择合同生效日期',
      },
      {
        label: '合同截止日期',
        fieldName: 'contractEndAt',
        type: ETableItemType.DATE,
        placeholder: '请选择合同截止日期',
      },
    ],
    [
      {
        label: '项目总户数',
        fieldName: 'housesTotal',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入总户数',
        placeholder: '请输入总户数',
      },
      {
        label: '项目电梯数',
        fieldName: 'elevatorsTotal',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入电梯数',
        placeholder: '请输入电梯数',
      },
    ],
    [
      {
        label: '合同照片',
        fieldName: 'contract',
        type: ETableItemType.UPLOAD_IMAGE,
        meta: {
          multiple: true,
          maxLimit: 6,
        },
        required: false,
        placeholder:
          '请上传合同首页、管理面积页、服务费用页以及合同签署页，最多六张图片，大小不超过10M!',
      },
    ],
    [
      {
        label: '项目工作人员总数（含外包服务人员）',
        fieldName: 'staffTotal',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入工作人员总数',
        placeholder: '请输入工作人员总数',
      },
      {
        label: '项目交付使用时间',
        fieldName: 'startingAt',
        type: ETableItemType.DATE,
        required: true,
        requiredMessage: '请输入选择交付使用时间',
        placeholder: '请输入选择交付使用时间',
      },
    ],
    [
      {
        label: '物业服务费年应收',
        fieldName: 'annualReceivable',
        type: ETableItemType.INPUT,
        meta: {
          inputType: 'number',
        },
        required: false,
        requiredMessage: '请输入物业服务费年应收',
        placeholder: '请输入物业服务费年应收',
      },
      {
        label: '多种经营年收入',
        fieldName: 'amateurIncome',
        type: ETableItemType.INPUT,
        meta: {
          inputType: 'number',
        },
        required: false,
        requiredMessage: '请输入多种经营年收入',
        placeholder: '请输入多种经营年收入',
      },
    ],

    [
      {
        label: '公共部位共用设备公众责任保险是否参保',
        fieldName: 'insured',
        type: ETableItemType.RADIO,
        required: true,
        requiredMessage: '请选择是否参保',
        placeholder: '',
      },
    ],
    [
      {
        label: '是否成立业委会',
        fieldName: 'ownerCommittee',
        type: ETableItemType.RADIO,
        required: true,
        requiredMessage: '请选择是否成立业委会',
        placeholder: '',
      },
    ],
  ],
  formData: new Project({}),
}

export const employeeTableSetting: ITableSetting<Employee> = {
  setting: [
    [
      {
        label: '姓名',
        fieldName: 'name',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入姓名',
        placeholder: '请输入姓名',
      },
      {
        label: '身份证号',
        fieldName: 'identityCardNum',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入身份证号',
        placeholder: '请输入身份证号',
        validator: (_rule: unknown, value: string, callback: IFormValidateCallback) => {
          const isValid = testIdCard(value)
          if (!value) {
            callback(new Error('身份证号不能为空!'))
          }
          if (isValid) {
            callback()
          } else {
            callback(new Error('身份证号格式不正确！'))
          }
        },
      },
    ],
    [
      {
        label: '技术职业证书',
        fieldName: 'documentPhotoList',
        type: ETableItemType.UPLOAD_IMAGE,
        meta: {
          maxLimit: 6,
          multiple: true,
        },
        required: true,
        requiredMessage: '请上传证书照片',
        placeholder: '支持上传jpeg、jpg、png格式图片，最多六张图片，大小不超过10M!',
      },
    ],
  ],
  formData: new Employee({}),
}

export const behaviorTableSetting: ITableSetting<BehaviorAction> = {
  setting: [
    [
      {
        label: '获奖名称',
        fieldName: 'sketch',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入获奖名称',
        placeholder: '请输入获奖名称',
      },
      {
        label: '获奖时间',
        fieldName: 'recognition',
        type: ETableItemType.DATE,
        required: true,
        requiredMessage: '请选择获奖时间',
        placeholder: '请选择获奖时间',
      },
    ],
    [
      {
        label: '颁奖单位',
        fieldName: 'recognitionOrgan',
        type: ETableItemType.INPUT,
        required: true,
        requiredMessage: '请输入颁奖单位',
        placeholder: '请输入颁奖单位',
      },
    ],
    [
      {
        label: '证明材料照片',
        fieldName: 'provePhotoList',
        type: ETableItemType.UPLOAD_IMAGE,
        meta: {
          maxLimit: 6,
          multiple: true,
        },
        required: true,
        requiredMessage: '请上传证明材料照片',
        placeholder: '支持上传jpeg、jpg、png格式图片，最多六张图片，大小不超过10M!',
      },
    ],
  ],
}

export const joinActivityTableSetting: ITableSetting = {
  setting: [
    [
      {
        label: '活动项目',
        fieldName: 'businessIds',
        type: ETableItemType.SELECT,
        required: true,
        requiredMessage: '请选择参与活动的项目',
        placeholder: '请选择参与活动的项目',
        meta: {
          multiple: true,
        },
      },
    ],
  ],
}
